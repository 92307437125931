import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserAvatarService } from '../../modules/maintainers/services/user-avatar.service';

var misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  showMenu = false;
  public menuItems: any[] = [];
  public isCollapsed = false;
  public user: any;
  public sidebarH: any;

  constructor(
    private toastr: ToastrService, 
    private router: Router,
    private userAvatarService: UserAvatarService
  ) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      const savedAvatar = this.userAvatarService.getStoredAvatar();
      this.user.avatar = savedAvatar;
    }
    
    let modules = this.user.modules;
    let submodules = this.user.submodules;

    for (let itemModules of modules) {
      this.menuItems.push({
        idModule: itemModules.idModule,
        path: itemModules.path,
        title: itemModules.name,
        type: itemModules.type,
        icontype: itemModules.icon,
        collapse: itemModules.collapse,
        isCollapsed: false,
        children: [],
      });
    }

    for (let itemMenuItem of this.menuItems) {
      for (let itemSubModule of submodules) {
        if (itemMenuItem.idModule == itemSubModule.submodules_fk_module) {
          itemMenuItem.children.push({
            path: itemSubModule.submodules_path,
            title: itemSubModule.submodules_name_submodule,
            ab: itemSubModule.submodules_icon_submodule,
          });
        }
      }
    }

    this.showMenu = true;

    this.minimizeSidebarAuto();

    // Suscribirse a cambios en el usuario
    this.userAvatarService.currentUserData.subscribe(userData => {
      if (userData) {
        this.user = userData;
        
      }
    });

    // Mantener la suscripción al avatar también
    this.userAvatarService.currentAvatar.subscribe(avatar => {
      if (avatar) {
        this.user = JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute('data-toggle') != 'collapse' &&
        element != document.getElementsByTagName('html')[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (element.classList.contains('collapse') && !element.classList.contains('show')) {
        element.classList = 'before-collapsing';
        var style = element.scrollHeight;

        element.classList = 'collapsing';
        setTimeout(function () {
          element.setAttribute('style', 'height:' + style + 'px');
        }, 1);
        setTimeout(function () {
          element.classList = 'collapse show';
          element.removeAttribute('style');
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute('style', 'height:' + (style + 20) + 'px');
        }, 3);
        setTimeout(function () {
          element.classList = 'collapsing';
        }, 3);
        setTimeout(function () {
          element.removeAttribute('style');
        }, 20);
        setTimeout(function () {
          element.classList = 'collapse';
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }

  minimizeSidebarAuto() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('sidebar-mini');
    misc.sidebar_mini_active = true;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show('<span class="now-ui-icons ui-1_bell-53"></span>', message, {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-top-right',
    });
  }

  logout() {
    const savedAvatar = this.userAvatarService.getStoredAvatar();
    const userAvatar = localStorage.getItem('userAvatar');
    localStorage.clear();
    if (savedAvatar) {
      localStorage.setItem('userAvatar', savedAvatar);
    }
    if (userAvatar) {
      localStorage.setItem('lastUserAvatar', userAvatar);
    }
    this.router.navigateByUrl('/auth/login');
  }

  
  private flagMap = {
    '1': 'assets/img/flags/chile.svg',
    '2': 'assets/img/flags/peru.svg',
    '3': 'assets/img/flags/colombia.svg',
    '4': 'assets/img/flags/mexico.svg'
};

getCountryFlag(): string {
    if (!this.user?.country || !this.user.country.length) {
        console.warn('No se encontró información del país, usando bandera chilena por defecto');
        return 'assets/img/flags/chile.svg';
    }
    
    const countryId = this.user.country[0]?.idCountry?.toString();
    const flagPath = this.flagMap[countryId] || 'assets/img/flags/chile.svg';
    return flagPath;
}

handleImageError(event: any) {
    console.error('Error al cargar la imagen de la bandera:', event);
    event.target.src = 'assets/img/flags/default.png';
}
}

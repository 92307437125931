import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NewsService } from '../services/news.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../../../auth/services/auth.service";
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-floating-banner',
  templateUrl: './floating-banner.component.html',
  styleUrls: ['./floating-banner.component.css']
})
export class FloatingBannerComponent implements OnInit, OnDestroy {
  infoList: any[] = [];
  loading = false;
  showInfo = false;
  private intervalId: any = null;
  private timeUpdateInterval: any = null;

  constructor(
    private newsService: NewsService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    return;
    this.fetchNews();
    this.intervalId = setInterval(() => {
      this.fetchNews();
    }, 300000);

    this.timeUpdateInterval = setInterval(() => {
      this.updateTimes();
    }, 60000);
  }

  private formatMessage(item: any): string {
    if (item.reference === 'fa_awb') {
      const action = item.action?.toLowerCase();
      if (action === 'insert') {
        return `Guía master ${item.codeAwb} se creó`;
      } else if (action === 'update') {
        return `Guía master ${item.codeAwb} se ha modificado`;
      } else if (action === 'delete') {
        return `Guía master ${item.codeAwb} se ha eliminado`;
      }
    }
    if (item.reference === 'fa_route_awb') {
      const action = item.action?.toLowerCase();
      if (action === 'insert') {
        return `Viaje de ${item.codeAwb} se creó`;
      } else if (action === 'update') {
        return `Viaje de ${item.codeAwb} se ha modificado`;
      } else if (action === 'delete') {
        return `Viaje de ${item.codeAwb} se ha eliminado`;
      }
    }
    return `${item.reference || ''} - ${item.action || ''}`;
  
  }

  private async fetchNews(): Promise<void> {
    try {
      this.loading = true;
      
      const type = environment.TYPE;
      if (!type || typeof type !== 'string' || !type.trim()) {
        throw new Error('Invalid TYPE configuration in environment');
      }

      const response = await lastValueFrom(
        this.newsService.getNews(type.trim())
      );

      if (!response?.body?.data) {
        throw new Error('No response received from server');
      }

      const newsData = response.body.data;
      const now = new Date();
      this.infoList = newsData.map(item => ({
        id: item.id,
        date: item.date,
        reference: item.reference || '',
        action: item.action || '',
        codeAwb: item.codeAwb || '',
        code: item.codeAwb || '',
        message: this.formatMessage(item),
        timeAgo: this.getTimeAgo(item.date)
      }));

      this.showInfo = true;
      this.loading = false;
      this.cdr.detectChanges();

    } catch (error) {
      this.loading = false;
      this.cdr.detectChanges();
      console.error('Error al obtener las noticias:', error);
      if (error.status === 401) {
        localStorage.clear();
        this.router.navigateByUrl('/auth/login');
        return;
      }

      this.toastr.error(
        error.error?.message || 'Error al obtener las noticias', 
        'Error', 
        {
          timeOut: 3000,
          closeButton: true,
          enableHtml: true,
          toastClass: 'alert alert-danger alert-with-icon',
          positionClass: 'toast-top-right',
        }
      );
    }
  }

  private updateTimes(): void {
    if (this.infoList.length > 0) {
      this.infoList = this.infoList.map(item => ({
        ...item,
        timeAgo: this.getTimeAgo(item.date)
      }));
      this.cdr.detectChanges();
    }
  }

  private getTimeAgo(date: string | Date): string {
    const systemDate = new Date();
    
    let dbDate: Date;
    if (typeof date === 'string') {
      const [datePart, timePart] = date.split(' ');
      const [day, month, year] = datePart.split('-');
      dbDate = new Date(`${year}-${month}-${day}T${timePart}`);
      dbDate.setHours(dbDate.getHours() - 3);
    } else {
      dbDate = new Date(date);
      dbDate.setHours(dbDate.getHours() - 3);
    }

    if (dbDate.getDate() === systemDate.getDate() && 
        dbDate.getMonth() === systemDate.getMonth() && 
        dbDate.getFullYear() === systemDate.getFullYear()) {
      
      const diff = Math.abs(systemDate.getTime() - dbDate.getTime());
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      
      if (minutes < 1) {
        return 'hace un momento';
      }
      if (minutes === 1) {
        return 'hace 1 minuto';
      }
      if (minutes < 60) {
        return `hace ${minutes} minutos`;
      }
      if (hours === 1) {
        return remainingMinutes > 0 ? 
          `hace 1 hora y ${remainingMinutes} minutos` : 
          'hace 1 hora';
      }
      return remainingMinutes > 0 ? 
        `hace ${hours} horas y ${remainingMinutes} minutos` : 
        `hace ${hours} horas`;
    }
    
    if (dbDate > systemDate) {
      return '0 minutos';
    }

    const diffInMilliseconds = systemDate.getTime() - dbDate.getTime();
    const totalHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(totalHours / 24);
    const remainingHours = totalHours % 24;

    if (totalHours < 24) {
      return `${totalHours} horas`;
    } else {
      if (remainingHours === 0) {
        return `${days} días`;
      } else {
        return `${days} días y ${remainingHours} horas`;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
  }
}
